import React, { useRef } from "react"
import "_data/normalize.css"
import { ScreensLayout } from "_components/indexScreens/common/screensLayout"
import Content from "_components/indexScreens/_pastPresentFuture/ppf_content"
import Seo from "_components/indexScreens/_pastPresentFuture/ppf_seo"

const currentScreen = 4
const initialUpperContentMargin = 120
const initialLowerContentMargin = 230
const screenBackgroundColor = "white"
const screenSwipeBackgroundColor = "#f4f4f4"

const PastPresentFuture = () => {
  // const [showFooter, setShowFooter] = useState(true)
  // const [previousPageAllowed, setPreviousPageAllowed] = useState(true)
  // const [nextPageAllowed, setNextPageAllowed] = useState(false)
  const showFooter = useRef(true)
  const previousPageAllowed = useRef(true)
  const nextPageAllowed = useRef(false)

  return (
    <>
      <Seo />
      {ScreensLayout(
        Content,
        screenSwipeBackgroundColor,
        screenBackgroundColor,
        initialUpperContentMargin,
        initialLowerContentMargin,
        showFooter.current,
        nextPageAllowed.current,
        previousPageAllowed.current,
        currentScreen
      )}
    </>
  )
}

export default PastPresentFuture
