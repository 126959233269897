import React from "react"
import { motion } from "framer-motion"
import { FaSlideshare } from "react-icons/fa"
// import { faShareSquare } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { color_primButton } from "_src/assets/data/global_colors"
import styled from "styled-components"

const Icon = (props) => {
  return (
    <OnHover
      transition={{
        type: "spring",
        mass: 2,
        damping: 25,
        restDelta: 0.001,
        restSpeed: 0.001,
      }}
      whileHover={{ scale: 1.035, rotateZ: 1 }}
    >
      <OnLoad
        initial={{ y: 15, opacity: 0, rotate: "4deg" }}
        animate={{ y: 0, opacity: 1, rotate: "7deg" }}
        transition={{ delay: 0.65, duration: 1, ease: "easeInOut" }}
      >
        <Parallax style={{ x: props.iconX, y: props.iconY }}>
          <div
            style={{
              //   fontSize: "12em",
              color: color_primButton,
            }}
          >
            <FaSlideshare />{" "}
          </div>
        </Parallax>
      </OnLoad>
    </OnHover>
  )
}

const OnHover = styled(motion.div)``
const Parallax = styled(motion.div)`
  font-size: 12em;
  filter: drop-shadow(10px 10px 10px rgba(50, 50, 73, 0.25));
  @media only screen and (max-width: 400px) {
    font-size: 9em;
  }
`
const OnLoad = styled(motion.div)``

export default Icon
