import React from "react"
import { motion } from "framer-motion"
import { Paragraph } from "_data/global_styles"
import PageHeadline from "_components/misc/pageHeadline"
import { Link } from "gatsby"
import {
  color_paragraph,
  color_paragraphHighlite,
} from "_src/assets/data/global_colors"

const Copy = (props) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <motion.div
        initial={{ y: 30, opacity: 0, rotate: 0.01 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 1.45, ease: "easeOut" }}
      >
        <motion.div style={{ x: props.titleX, y: props.titleY }}>
          <PageHeadline copy="The past, present, and future of our EMDR tools." />
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ y: 30, opacity: 0, rotate: 0.01 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.65, duration: 1.75, ease: "easeOut" }}
        style={{ marginLeft: ".5vw", color: "#303c6c" }}
      >
        <motion.div style={{ x: props.paraX, y: props.paraY }}>
          <Paragraph style={{ color: color_paragraph }}>
            Let's face it, life has been interesting.  Everyone
            has had to face new challenges, including therapists! You've had to
            deal with a whole new way of working with your clients. Not to
            mention having to sort through the technology that will allow you to
            continue to help others, but now from a distance. It may not be
            something you anticipated.
          </Paragraph>
          <Paragraph style={{ color: color_paragraph, textAlign: "center" }}>
            <span
              style={{
                fontWeight: "800",
                textDecoration: "none",
                color: color_paragraphHighlite,
              }}
            >
              We recognize that therapists may be having a crisis of their own.
            </span>
          </Paragraph>
          <Paragraph style={{ color: color_paragraph }}>
            My name is Rick May, I'm a developer and designer who originally
            created an EMDR Windows application in 2013 for my EMDRIA Certified
            Therapist and Approved Consultant wife, Dr. Tara May.
          </Paragraph>
          <Paragraph style={{ color: color_paragraph }}>
            When COVID-19 came along and wrecked the in-person therapist / client
            relationship, it was decided that the tools that she had been using
            for so many years should be re-imagined as a web-app and shared with
            everyone.
          </Paragraph>
          <Paragraph style={{ color: color_paragraph }}>
            <span
              style={{
                fontWeight: "800",
                textDecoration: "none",
                color: color_paragraphHighlite,
              }}
            >
              {" "}
              ActiveEMDR
            </span>{" "}
            is my gift to therapists who need a reliable way to continue to
            offer EMDR to their clients who are no longer sitting next to them.
            {/* I'm not looking for compensation, but want to help those that have
            been helping others for so long. */}
          </Paragraph>{" "}
          {/* <Paragraph style={{ color: color_paragraph }}>
            We've got big plans and want you to be a part of it! We are
            investigating new and unique ideas that hasn't been seen before in
            other virtual EMDR tools. All of which will allow you to be even
            more successful delivering the best EMDR treatment possible.{" "}
          </Paragraph> */}
          <Paragraph style={{ color: color_paragraph }}>
            {Join()} to be informed of {ChangeLog()} and scheduled down time.
            And as always, {Contact()} us with ideas or requests.
          </Paragraph>
        </motion.div>
      </motion.div>
    </div>
  )

  function ChangeLog() {
    return (
      <Link
        style={{
          color: "#123a82",
          fontWeight: "800",
          cursor: "pointer",
          textDecoration: "none",
        }}
        to="/changelog"
      >
        changes
      </Link>
    )
  }

  function Contact() {
    return (
      <Link
        style={{
          color: "#123a82",
          fontWeight: "800",
          cursor: "pointer",
          textDecoration: "none",
        }}
        to="/contact"
      >
        contact
      </Link>
    )
  }

  function Join() {
    return (
      <Link
        style={{
          color: "#123a82",
          fontWeight: "800",
          cursor: "pointer",
          textDecoration: "none",
        }}
        to="/signup"
      >
        Join our mailing list
      </Link>
    )
  }
}

export default Copy
