import React, { useEffect } from "react"
import { useSpring, useTransform } from "framer-motion"
import { useMitt } from "react-mitt"
import Copy from "./ppf_content-copy"
import Icon from "./ppf_content-icon"
import styled from "styled-components"

const WhoWhatContent = () => {
  const { emitter } = useMitt()
  let mouseX = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let mouseY = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let titleX = useTransform(mouseX, (value) => value / 440)
  let titleY = useTransform(mouseY, (value) => value / 420)
  let paraX = useTransform(mouseX, (value) => value / 520)
  let paraY = useTransform(mouseY, (value) => value / 580)
  let iconX = useTransform(mouseX, (value) => value / 600)
  let iconY = useTransform(mouseY, (value) => value / 600)

  useEffect(() => {
    emitter.on("mouseX", (event) => {
      const offsetX = event.data - window.innerWidth / 2
      mouseX.set(offsetX)
    })

    emitter.on("mouseY", (event) => {
      const offsetY = event.data - window.innerHeight / 2
      mouseY.set(offsetY)
    })
  }, [emitter, mouseY, mouseX])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Content>
        <Primary>
          <Copy titleX={titleX} titleY={titleY} paraX={paraX} paraY={paraY} />
        </Primary>
        <Secondary>
          <Icon iconX={iconX} iconY={iconY} />
        </Secondary>
      </Content>
    </div>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;
  width: 90%;
  max-width: 1050px;
  @media only screen and (max-width: 2000px) {
    width: 95%;
  }
  @media only screen and (max-width: 1150px) {
    width: 80%;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    width: 70%;
  }
  @media only screen and (max-width: 650px) {
    width: 90%;
  }
`

const Primary = styled.div`
  order: 1;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 900px) {
    order: 2;
    width: 100%;
  }
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`

const Secondary = styled.div`
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3vw;
  @media only screen and (max-width: 900px) {
    order: 1;
    margin: 0 0 5vh 0;
  }
  @media only screen and (max-width: 400px) {
    display: none;
  }
`

export default WhoWhatContent
